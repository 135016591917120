@import "../styles/variables.scss";

.main {
    width: 100vw;
    min-height: 100vh;
    //overflow: hidden;
    //display: grid;
}

.top {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    border-bottom: 1px solid white;

    a {
        font-size: 28pt;
        margin-bottom: 0;
        padding: 0;
        text-decoration: none;
       }
}

.levels {
    padding: 20px 20px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include tablet {
        grid-template-columns: 1fr 1fr 1fr ;
    }

    @include desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}