@import "../styles/variables.scss";

.main {
    width: 100%;
    height: 100vw;
    top: max(calc(50% - 50vw), 0px);
    touch-action: none;
    position: absolute;
    overflow: hidden;
    z-index: 1;

 

    @include desktop {
        top: 0;
        width: calc(100vh - 55px);
        height: calc(100vh - 55px);
        left: max(calc(50% - (50vh - 22.5px)), 0px);
    }
}

.shader {
    width: 100%;
    height: 100%;
    pointer-events: none;
    touch-action: none;
    overflow: hidden;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 100;
}