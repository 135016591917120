@import "../styles/variables.scss";

.main {
    width: auto;
    background-color: $white;
    color: $black;
    text-decoration: none;
    padding: 15px 30px;
    margin-bottom: 30px;
    font-size: 15pt;
    text-align: center;
    clip-path: polygon(
    0 10px,
    10px 0,
    calc(100% - 10px) 0,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 10px),
    0% 10px
     );

    @include tablet {
        font-size: 25pt;
    }

    @include desktop {
        font-size: 35pt;
    }

    &.inverted {
        background-color: $black;
        color: $white;
    }

    &:hover {
        background-color: $pink;
        color: $white;

        &:nth-of-type(even) {
            background-color: $blue;
        }
    }

    &.sm {
        padding: 5px 15px;
        margin-bottom: 0px;
        font-size: 12pt;

        @include tablet {
            font-size: 18pt;
        }
    
        @include desktop {
            font-size: 20pt;
        }

        clip-path: polygon(
            0 5px,
            5px 0,
            calc(100% - 5px) 0,
            100% 5px,
            100% calc(100% - 5px),
            calc(100% - 5px) 100%,
            5px 100%,
            0% calc(100% - 5px),
            0% 5px
             );
    }
}