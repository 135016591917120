@font-face {
    font-family: 'PerfectDOSVGA437';
    src: url('./fonts/PerfectDOSVGA437.woff2') format('woff2'),
        url('./fonts/PerfectDOSVGA437.woff') format('woff'),
        url('./fonts/PerfectDOSVGA437.svg#PerfectDOSVGA437') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4, h5, h6, a, p, pre, span, body, button, input {
    font-family: "PerfectDOSVGA437", sans-serif;
    color: white;
}