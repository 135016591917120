@import "../styles/variables.scss";

.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: [start] auto [end];
  grid-template-rows: [topbar] 55px [controls] 180px [main] calc(100% - 235px);

  @include tablet {
    grid-template-columns: [start] auto [end];
    grid-template-rows: [topbar] 55px [controls] 210px [main] calc(100% - 245px);
  }

  @include desktop {
    height: 100vh;
    grid-template-columns: [side] 260px [game] calc(100% - 260px);
    grid-template-rows: [topbar] 55px [main] calc(100% - 55px);
  }
}

.top {
    grid-column: start / end;
    grid-row: topbar / topbar;
  border-bottom: 1pt solid $white;

  a {
    font-size: 20pt;
    margin-bottom: 0;
    padding: 0;
    text-decoration: none;

    @include tablet {
        font-size: 28pt;
    }

    
    
  }

  .topButtons {
    display: flex;
    //justify-content: space-between;
    a {
      &:first-of-type {
        margin-right: 10px;
        display: none;
  
        @include tablet {
          display: unset;
        }
      }
    }

    .muteButton {
      width: auto;
      height: 100%;
      background-color: white;
      border: none;
      margin-right: 10px;
      padding: 5px;
      clip-path: polygon(
            0 5px,
            5px 0,
            calc(100% - 5px) 0,
            100% 5px,
            100% calc(100% - 5px),
            calc(100% - 5px) 100%,
            5px 100%,
            0% calc(100% - 5px),
            0% 5px
             );
    
      img {
        width: auto;
        height: 25px;
      }
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  @include tablet {
    padding: 0px 30px;
  }

  @include desktop {
    grid-column: side / end;
    grid-row: topbar / topbar;
    padding: 0px 10px;
  }
}

.side {
    grid-column: start / end;
    grid-row: controls / controls;
    border-bottom: 1pt solid $white;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;

  @include tablet {
    padding: 20px 30px;
  }

  @include desktop {
    grid-column: side / side;
    grid-row: main / end;
    border-bottom: none;
    border-right: 1pt solid $white;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 10px;
  }
}

.angle {
  width: 100%;
  // margin-right: 20px;
  background-color: white;
  padding: 15px 10px;
  display: flex;

  div {
    width: 100%;
  }

  h1 {
    color: $black;
    font-size: 20pt;
  }

  @include desktop {
    width: 100%;
    background-color: white;
    padding: 15px 10px;
    flex-direction: column;

    h1 {
      font-size: 30pt;
    }
  }

  .angleControls {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10px;
    //margin-bottom: 30px;

    @include desktop {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      margin-bottom: 30px;
    }

    input {
      background-color: $black;
      height: 100%;
      padding: 6px 10px;
      width: 100%;
      border: none;

      @include desktop {
        width: 45%;
        font-size: 24pt;
      }

      clip-path: polygon(
        0 2px,
        2px 0,
        calc(100% - 2px) 0,
        100% 2px,
        100% calc(100% - 2px),
        calc(100% - 2px) 100%,
        2px 100%,
        0% calc(100% - 2px),
        0% 2px
      );
    }
  }

  .angleButton {
    width: 45%;
    padding-bottom: 35%;
    margin-bottom: 10px;
    position: relative;
    border: 2px solid $black;
    background-color: white;
    clip-path: polygon(
      0 2px,
      2px 0,
      calc(100% - 2px) 0,
      100% 2px,
      100% calc(100% - 2px),
      calc(100% - 2px) 100%,
      2px 100%,
      0% calc(100% - 2px),
      0% 2px
    );

    @include desktop {
      margin-bottom: unset;
      width: 20%;
      padding-bottom: 18%;
    }

    img {
      width: 100%;
      height: 100%;
      padding: 5px;
      position: absolute;
      object-fit: contain;
      top: 0;
      left: 0;

      &.down {
        transform: rotate(180deg);
      }
    }
  }
}

.controls {
  width: 55%;
  height: auto;

  clip-path: polygon(
    0 5px,
    5px 0,
    calc(100% - 5px) 0,
    100% 5px,
    100% calc(100% - 5px),
    calc(100% - 5px) 100%,
    5px 100%,
    0% calc(100% - 5px),
    0% 5px
  );

  @include tablet {
    width: 45%;
  }

  @include desktop {
    width: 100%;
    height: auto;
  }

}

.controlsButton {
  border: none;
  width: 100%;
  background-color: $black;
  color: white;
  padding: 10px 10px;
  font-size: 12pt;
  margin-bottom: 5px;
  clip-path: polygon(
    0 5px,
    5px 0,
    calc(100% - 5px) 0,
    100% 5px,
    100% calc(100% - 5px),
    calc(100% - 5px) 100%,
    5px 100%,
    0% calc(100% - 5px),
    0% 5px
  );

  @include tablet {
    font-size: 16pt;
  }

  @include desktop {
    font-size: 30pt;
  }

  &:hover {
    background-color: $pink;
    color: $black;
  }
}
.scores {

  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include desktop {
    width: 100%;
  }
}

.score {
  width: 100%;
  padding: 10px 10px;
  border: 2px solid white;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  clip-path: polygon(
    0 2px,
    2px 0,
    calc(100% - 2px) 0,
    100% 2px,
    100% calc(100% - 2px),
    calc(100% - 2px) 100%,
    2px 100%,
    0% calc(100% - 2px),
    0% 2px
  );

  &:first-child {
    margin-top: 0px;
  }

  p {
    color: $black;
    background-color: white;
    padding: 3px 4px;
    width: 40%;
    text-align: right;
    min-width: 50px;
    clip-path: polygon(
      0 3px,
      3px 0,
      calc(100% - 3px) 0,
      100% 3px,
      100% calc(100% - 3px),
      calc(100% - 3px) 100%,
      3px 100%,
      0% calc(100% - 3px),
      0% 3px
    );
  }

  @include desktop {
    h1 {
      font-size: 20pt;
    }

    p {
      font-size: 20pt;
    }
  }
}

.game {
  grid-column: start / end;
  grid-row: main / end;
  position: relative;

  @include tablet {
    grid-column: start / end;
    grid-row: main / end;
  }

  @include desktop {
    grid-column: game / end;
    grid-row: main / end;
  }
}

.win {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  background-color: white;
  z-index: 100;
  padding: 20px 20px;
  clip-path: polygon(
    0 10px,
    10px 0,
    calc(100% - 10px) 0,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 10px),
    0% 10px
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include tablet {
    width: 80%;
  height: 50%;
  top: 25%;
  left: 10%;
  padding: 20px 60px;
  }

  @include desktop {
width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  
  }

 

  h1 {
    color: black;
    font-size: 40pt;
    margin-bottom: 30px;
    text-align: center;

    @include desktop {
      font-size: 60pt;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include tablet {
        width: 50%;
    }
  }

  .nextLinks {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: auto;
    

    a {
      margin-bottom: 20px;
      font-size: 25pt;
    }

    @include desktop {
      a {
        font-size: 30pt;
      }
    }
  }

  .scores {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    margin-bottom: 50px;

    @include tablet {
        width: auto;
    }

    @include desktop {
        width: 750px;
    }
  }

  .score {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border: 5px solid $black;
    margin-top: 0px;
    clip-path: polygon(
      0 3px,
      3px 0,
      calc(100% - 3px) 0,
      100% 3px,
      100% calc(100% - 3px),
      calc(100% - 3px) 100%,
      3px 100%,
      0% calc(100% - 3px),
      0% 3px
    );

    

    p {
      padding: 5px 5px;
      font-size: 12pt;
      text-align: center;
      vertical-align: center;
    }
    h1 {
      font-size: 14pt;
      text-align: center;
      margin-bottom: 0px;
      color: $black;
    }
    
    @include desktop {
      align-items: center;
    justify-content: space-between;
    flex-direction: row;
      p {
        font-size: 24pt;
        text-align: right;
      }
      h1 {
        font-size: 24pt;
      }
    }
  }
}

