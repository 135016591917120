@import "../styles/variables.scss";

.main {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.heading {
    text-align: center;
    width: auto;
    display: flex;
    margin-bottom: 40px;

    h1 {
        font-size: 60pt;
        padding: 0px 30px;
    }

    img {
        width: 50px;
        padding-bottom: 10px;
    }

}

.buttons {
    width: auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
}