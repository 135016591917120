@import "../styles/variables.scss";

.main {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    //overflow: hidden;
    //display: grid;
}

.top {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    border-bottom: 1px solid white;
    position: fixed;
    background-color: $black;

    a {
        font-size: 28pt;
        margin-bottom: 0;
        padding: 0;
        text-decoration: none;
       }
}

.content {
    padding: 100px 20px;
    width: 100%;
    max-width: 800px;

    p {
        padding-bottom: 20px;
    }

    h1 {
        font-size: 30pt;
        @include desktop {
            font-size: 60pt;
        }
    }

    h2 {
        font-size: 40pt;
        padding-top: 60px;
    }

    .images {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-bottom: 20px;

        @include desktop {
            flex-direction: row;
        }
    }

    img {
        height: auto;
        width: 100%;
        border: 5px solid white;

        @include desktop {
            width: 49%;
        }
    }
}