@import "./variables.scss";
@import "./reset.scss";

body {
    background-color: $black;
}

h1, h2, h3, h4, h5, h6, p, pre, a {
    color: $white;

    &::selection {
        background-color: $white;
        background: $white;
        color: $black;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}